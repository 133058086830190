import React, { useState, useEffect, useRef } from 'react';
import TootGrid from './TootGrid'
import LoadMore from './LoadMore'


function RecentTootsContainer() {
  const [data_status, setDataStatus] = useState('loading')
  const [data, setData] = useState([])
  const [last_auto_id, setLastAutoId] = useState(null)
  const [counter, setCounter] = useState(0)
  const [load_more, setLoadMore] = useState(true)

  async function getRecentToots() {
    const api_uri = process.env.REACT_APP_API_URI
    const api_limit = process.env.REACT_APP_API_LIMIT
    const api_url = api_uri + '/api/v1/recent_toots?use_cache=1&limit=' + api_limit

    /*
    fetch( api_url , {
        method: "POST",
        mode: "cors",
        credentials: "include", // include, same-origin, *omit
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(login_data)
    })
    */

    let response = null
    try {
      response = await fetch(
        api_url,
        {
          method: "GET",
          mode: "cors",
          credentials: "include",
        }
      )
    } catch(e) {
      console.log(e)
      setDataStatus('error')
      return
    }

    if (!response.ok) {
      if (response.status === 400 ) {
        const json_data = await response.json()
        if (json_data.display) {
          //setError(json_data.error)
          setDataStatus('error')
          return
        }
      }
      //setError("error info")
      setDataStatus('error')
      return
    }

    const json_data = await response.json()

    //DEBUG
    //console.log("GOT DATA")
    //console.log(json_data)

    // setGDocLink(json_data.doc_url)
    // setStatus("link_ready")
    //let new_toots = [...data]
    let new_toots = json_data.concat(data)
    
    //console.log("NEW TOOTS: ", new_toots)
    if (new_toots.length) {
      setLastAutoId(new_toots[new_toots.length - 1].auto_id)
    }
    
    setData(new_toots)
    //setData([ { auto_id: 1 } ])
    setDataStatus('ready')
  }

  async function loadMoreToots() {
    if ( counter >= 10 ) {
      setLoadMore(false)
      return
    }
    setDataStatus('loading')

    const api_uri = process.env.REACT_APP_API_URI
    const api_limit = process.env.REACT_APP_API_LIMIT
    const api_url = api_uri + '/api/v1/toots?limit=' + api_limit + '&start_id=' + (last_auto_id - 1)

    let response = null
    try {
      response = await fetch(
        api_url,
        {
          method: "GET",
          mode: "cors",
          credentials: "include",
        }
      )
    } catch(e) {
      console.log(e)
      setDataStatus('error')
      return
    }

    if (!response.ok) {
      if (response.status === 400 ) {
        const json_data = await response.json()
        if (json_data.display) {
          //setError(json_data.error)
          setDataStatus('error')
          return
        }
      }
      //setError("error info")
      setDataStatus('error')
      return
    }

    const json_data = await response.json()

    if ( json_data.length == 0 ) {
      setLoadMore(false)
      return
    }

    let new_toots = data.concat(json_data)
    if (new_toots) {
      setLastAutoId(new_toots[new_toots.length - 1].auto_id)
    }
    
    setData(new_toots)
    setCounter( counter => counter + 1 )
    setDataStatus('ready')
  }


  useEffect(() => {
    getRecentToots()
  }, [])



  // let load_widget = null
  // if ( data_status === 'loading' ){
  //   load_widget = (
  //     <div>loading ... </div>
  //   )
  // } else if ( data_status === 'error' ) {
  //   load_widget = (
  //     <div>
  //       error <br/>
  //       Load More Button
  //     </div>
  //   )
  // } else {
  //   load_widget = (
  //     <div>Load More Button</div>
  //   )
  // }

  // toots_grid = <TootsGrid toot_data={data}>
  /*
  let toots = []
  for (const toot of data){
    const content = {__html: toot.toot_data.content_clean };
    const avatar_url = toot.toot_data.account.avatar_static_cdn.cdn_url

    let media_attachment = null
    if ( toot.toot_data.media_attachments_cdn ) {
      media_attachment = (
        <img src={ toot.toot_data.media_attachments_cdn[0].cdn_url } />
      )
    }


    toots.push(
      <div key={toot.auto_id}>
        =================== <br/>
        <img src={avatar_url} style={ {width: '2rem', 'height': '2rem'} } /> <br/>
        Auto id: {toot.auto_id}, {toot.toot_data.account.username} <br/>
        <div dangerouslySetInnerHTML={content} />
        {media_attachment}
      </div>
    )
  }
  */

  // {toots}
  // {load_widget}
  return (
    <React.Fragment>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "1rem",
        fontFamily: 'Montserrat',
        fontSize: '0.9rem',
        backgroundColor: "white",
      }}>
        <div style={{ maxWidth: '40rem', minWidth: '0'}}>
          Recent toots from popular mastodon instances
        </div>
      </div>
      <TootGrid toots={data}/>
      <LoadMore
        onLoadMore={loadMoreToots} data_status={data_status}
        error={null} enabled={load_more}
      />
    </React.Fragment>
  )
}


export default RecentTootsContainer