import React, { useState, useEffect, useRef } from 'react';
// import MastodoniaHeader from './MastodoniaHeader'
// import MastodoniaFooter from './MastodoniaFooter'
import RecentTootsContainer from './RecentTootsContainer'
import MastodoniaHeader  from './MastodoniaHeader'
import MastodoniaFooter  from './MastodoniaFooter'

function AllTootsPage() {
  return (
    <React.Fragment>
      <MastodoniaHeader/>
      <RecentTootsContainer/>
      <MastodoniaFooter/>
    </React.Fragment>
  )
}

export default AllTootsPage
