import React, { useState, useEffect, useRef } from 'react';

export default function MastodoniaFooter() {
  // let site_link = (
  //   <a href="https://antoncodes.dev" class="text-dark">
  //     <u>https://antoncodes.dev</u>
  //   </a>
  // )
  let site_link = null


  return (
    <div style={{
      //marginTop: '4rem',
      //height: '10rem',
      //backgroundColor: '#d5d5d5',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'top',
      fontFamily: 'Montserrat',
    }}>
      <div style={{ width: "20rem", padding: '2rem', fontSize: '0.9rem' }}>
        Created by: <span> </span><span> </span>
        <a href="https://twitter.com/anton_codes" className="text-dark" target="_blank" rel="noopener noreferrer">
          <img style={{
            width: '1.2rem',
            height: '1.2rem',
            verticalAlign: "middle",
            display: 'inline',
            //border: "1px solid gray",
            //backgroundColor: 'gray',
            marginLeft: '0.4rem',
            marginRight: '0.4rem',
          }}
          src="./twitter_icon.svg"
          />
          <u>@anton_codes</u>
        </a> <br/>
        { site_link }
        Questions or comments: <span> </span>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSd97Jtv_ldRB9QF1kYk6ox3NCNqFtmle6le4m_KUw_X-jjDFw/viewform"
        className="text-dark" target="_blank" rel="noopener noreferrer">
          <u>click here</u>
        </a> <br/><br/>
        Pixelfed aggregator: <span> </span>
        <a href="https://pixelfed.club" className="text-dark" target="_blank" rel="noopener noreferrer">
          <u>pixelfed.club</u>
        </a>
        <br/>
      </div>
    </div>
  )
}