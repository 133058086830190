import React, { useState, useEffect, useRef } from 'react';

function renderNoImage(props) {
  const height = "6rem"
  return (
    <div className="card" style={{
      //width: '20rem',
      height: "6rem",
      padding: '0.5rem',
      fontSize: '0.7rem',
      overflow: 'hidden',
      fontFamily: 'Montserrat',
      borderWidth: '2px',
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    }}>
      <div style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        flexShrink: '0',
      }}>
        <a href={props.toot_url} target="_blank" rel="noopener noreferrer" >
        <img style={{
          width: '1.5rem',
          height: '1.5rem',
          verticalAlign: "middle",
          display: 'inline',
          border: "1px solid gray",
          backgroundColor: 'gray',
          marginRight: '0.2rem',
        }}
        src={props.avatar_url}
        />
        </a>
        <a href={props.toot_url} className="text-secondary" target="_blank" rel="noopener noreferrer" >
          {props.username}
        </a>
      </div>
      <div style={{
         fontSize: "0.7rem",
         flexGrow: '1',
         overflow: "hidden",
         textOverflow: "ellipsis",
      }} dangerouslySetInnerHTML={props.content} />
    </div>
  )
}

export default function Toot(props) {
  const toot = props.toot
  const toot_data = toot.toot_data
  const key = "" + toot_data.instance_hostname + "." + toot_data.id
  const username = "@" + toot_data.account.username + "@" + toot_data.instance_hostname
  const toot_url = toot_data.url
  let avatar_url = ''
  const avatar_cdn = toot_data.account.avatar_static_cdn
  if ( avatar_cdn ) {
    avatar_url = avatar_cdn.cdn_url
  }
  //const avatar_url = toot_data.account.avatar_static_cdn.cdn_url

  const content = {__html: toot_data.content_clean };

  let media_attachment_url = null
  let css_url = ''
  if ( toot_data.media_attachments_cdn ) {
      media_attachment_url = toot_data.media_attachments_cdn[0].cdn_url
      css_url = 'url("' + media_attachment_url + '")'
  }

  if ( ! media_attachment_url ) {
    return renderNoImage({
      toot_url: toot_url,
      avatar_url: avatar_url,
      username: username,
      content: content,
    })
  }

  return (
      <div className="card" style={{
        //width: '20rem',
        height: "6rem",
        padding: '0.5rem',
        fontSize: '0.7rem',
        overflow: 'hidden',
        fontFamily: 'Montserrat',

        display: "grid",
        gridTemplateColumns: "auto 6rem",
        // gridTemplateRows: "auto",
        gridTemplateRows: "100%",
        borderWidth: '2px',

      }}>
        <div style={{
          gridColumn: " 1 / 1 ",
          gridRow: " 1 / 1 ",
          overflow: "hidden",
        }}>
          <div style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            paddingRight: '1rem',
          }}>
            <a href={toot_url} target="_blank" rel="noopener noreferrer">
            <img style={{
              width: '1.5rem',
              height: '1.5rem',
              verticalAlign: "middle",
              display: 'inline',
              border: "1px solid gray",
              backgroundColor: 'gray',
              marginRight: '0.2rem',
            }}
            src={avatar_url}
            />
            </a>
            <a href={toot_url} className="text-secondary" target="_blank" rel="noopener noreferrer">
              {username}
            </a>
          </div>
          <div style={{
             fontSize: "0.7rem",
             //overflow: "hidden",
             textOverflow: "ellipsis",
          }} dangerouslySetInnerHTML={content} />
        </div>
        <div style={{
          gridColumn: " 2 / 2 ",
          gridRow: " 1 / 1 ",
          // border: "2px solid black",
          backgroundImage: css_url,
          backgroundSize: "cover",
          backgroundRepeat:   "no-repeat",
          backgroundPosition: "center center",
        }}></div>
      </div>
  )
}