import React, { useState, useEffect, useRef } from 'react';
import Toot from './Toot'

export default function TootGrid(props) {
  let toot_components = []
  for (const toot of props.toots){
    const toot_data = toot.toot_data
    const key = "" + toot_data.instance_hostname + "." + toot_data.id
    toot_components.push(
      <Toot key={key} width="22rem" toot={toot} />
    )
  }

  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: "repeat(auto-fill, 22rem)",
      //gridTemplateRows: "repeat(auto-fill, 10rem)",
      gridGap: "1rem",
      justifyContent: "center",
      backgroundColor: 'white',
      //paddingTop: "1rem",
      paddingBottom: "2rem",
    }}>
      { toot_components }
    </div>
  )
}