import React, { useState, useEffect, useRef } from 'react';

export default function MastodoniaHeader() {
  return (
    <div style={{
      height: "8rem",
      backgroundColor: "white",
      display: 'flex',
      flexDirection: "column",
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{
        display: 'inline-block',
        fontSize: '2.5rem',
        fontFamily: 'Montserrat',
        color: "#5d929e",
      }}>
        <img style={{
          width: '2.5rem',
          height: '2.5rem',
          verticalAlign: "middle",
          display: 'inline',
          //border: "1px solid gray",
          //backgroundColor: 'gray',
          marginRight: '0.6rem',
        }}
        src="./mastodon_logo.svg"
        />
        <span className="font-weight-bold">mastodonia.club</span>
      </div>
      <div style={{
        display: 'inline-block',
        fontSize: '0.9rem',
        fontFamily: 'Montserrat',
        color: "#5d929e",
      }}>
        Aggregator for <span> </span>
        <a href="https://joinmastodon.org/" target="_blank" rel="noopener noreferrer"
          style={{ color: "#5d929e", }}
        >
          <u>mastodon</u>
        </a> <span> </span>
        fediverse
      </div>
    </div>
  )
}